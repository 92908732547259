
import { defineComponent } from 'vue'
import { initialState } from '@/plugins/store'

interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

export default defineComponent({
  name: 'Import',
  methods: {
    exportFromStore () {
      const dataToExport = JSON.stringify(this.$store.state)
      const blob = new Blob([dataToExport], { type: 'text/plain' })
      const event = document.createEvent('MouseEvents')
      const linkElement = document.createElement('a')
      linkElement.download = 'myrocks.json'
      linkElement.href = window.URL.createObjectURL(blob)
      linkElement.dataset.downloadurl = ['text/json', linkElement.download, linkElement.href].join(':')
      event.initEvent('click', true, false)
      linkElement.dispatchEvent(event)
    },
    importToStore (event: HTMLInputEvent) {
      if (event.target.files) {
        const file = event.target.files[0]
        if (file.name.slice(-5) !== '.json') {
          this.alertMessage = 'Cannot import file!'
          this.alert = true
        } else {
          this.readFiles(file)
        }
        event.target.value = ''
      }
    },
    readFiles (file: Blob): void {
      const fileReader = new FileReader()
      fileReader.readAsText(file)
      fileReader.onload = () => {
        const importedData = JSON.parse(fileReader.result as string)
        this.$store.commit('importState', importedData)
        this.alertMessage = `Your ${this.$store.state.rockLists.tumbling.length} tumbling rocks and ${this.$store.state.rockLists.polished.length} polished rocks have been imported`
        this.alert = true
      }

      fileReader.onerror = () => {
        this.alertMessage = fileReader.error?.toString() ?? 'Error reading file'
        this.alert = true
      }
    },
    clearState () {
      this.$store.commit('importState', initialState())
      this.$router.push({ name: 'RockPicker' })
    }
  },
  data () {
    return {
      alert: false,
      alertMessage: ''
    }
  }
})
